<template>
    <div class="home">
        <div>
            <!-- 轮播图 -->
            <van-swipe
                class="home-swipe"
                :autoplay="3000"
                :show-indicators="false"
            >
                <van-swipe-item v-for="(item, index) in slides" :key="index">
                    <van-image height="13rem" fit="cover" :src="item.imgurl" />
                </van-swipe-item>
            </van-swipe>
        </div>
        <div>
            <!-- 栏目 -->
            <div class="home-words">
                <div class="home-words-category">
                    <div class="my-font-lg my-font-bold">最新动态</div>
                    <router-link to="/wordslist/1" class="my-font-md my-flex-row">
                        <span>更多</span>
                        <van-icon name="arrow" />
                    </router-link>
                </div>
                <div>
                    <div
                        v-for="(item, index) in blogs"
                        :key="index"
                        class="my-card"
                        style="margin-bottom: 0.5rem"
                    >
                        <div class="my-font-md" style="width: 100%;">
                            <div
                                class="home-words-card-title"
                                @click="toWordsPage(item.articleId)"
                                style="width: 100%; font-weight: bold;"
                            >
                                {{ cutContent(item.articleTitle) }}
                            </div>
                            <div class="home-words-down">
                                <span>{{
                                    item.userInfo.userNickname
                                }}</span>
                                <span>{{ getTime(item.articleAddtime) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getSlide, getHomePage } from "@/api/common";
String.prototype.getBothLength = function () {
    var len = 0;
    for (var i = 0; i < this.length; i++) {
        if (this.charCodeAt(i) > 127 || this.charCodeAt(i) == 94) {
            len += 2;
        } else {
            len++;
        }
    }
    return len;
};
export default {
    components: {},
    data() {
        return {
            imgUrl: [
                "https://s1.ax1x.com/2020/09/01/dxIucj.jpg",
                "https://s1.ax1x.com/2020/09/01/dxIlBq.jpg",
                "https://s1.ax1x.com/2020/09/01/dxIQun.jpg",
                "https://s1.ax1x.com/2020/09/01/dxIKjs.jpg",
                "https://s1.ax1x.com/2020/09/01/dxIn3Q.jpg",
                "https://s1.ax1x.com/2020/09/01/dxIGNT.jpg",
                "https://s1.ax1x.com/2020/09/01/dxI1H0.jpg",
                "https://s1.ax1x.com/2020/09/01/dxI8EV.jpg",
            ],
            test: "三星推出首款 5G 英特尔 Evo 笔记本 Galaxy BookFlex 5G，配备 1300 万像素的摄像头，内置 S Pen",
            blogs: [],
            slides: [],
        };
    },
    computed: {},
    created() {},
    mounted() {
        this.getSlide();
        this.getHomePage();
    },
    methods: {
        cutContent(text) {
            // 裁剪 blog 内容
            if (
                text
                    .replace(/<[^>]+>/g, "")
                    .replace(/&nbsp;/gi, "")
                    .getBothLength() >= 65
            ) {
                return (
                    text
                        .replace(/<[^>]+>/g, "")
                        .replace(/&nbsp;/gi, "")
                        .substr(0, 65) + "···"
                );
            } else {
                return text.replace(/<[^>]+>/g, "").replace(/&nbsp;/gi, "");
            }
        },
        toWordsPage(id) {
            this.$router.push({ name: "WordsPage", params: {id: id} });
        },
        getTime(time) {
            // 格式化时间
            return new Date(time).Format("yyyy-MM-dd");
        },
        getSlide() {
            getSlide().then((res) => {
                if (res.code === 20000) {
                    this.slides = res.data;
                } else {
                    this.$toast("获取轮播图失败！");
                }
            });
        },
        getHomePage() {
            getHomePage().then((res) => {
                if (res.code === 20000) {
                    this.blogs = res.data;
                } else {
                    this.$toast("获取首页内容失败！");
                }
            });
        },
    },
};
</script>

<style scoped>
.home-swipe {
    height: 13rem;

    background-color: #fff;
}

.home-words {
    margin-top: 1rem;
}

.home-words-category {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-right: 1rem;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
}

.home-card-flex {
    display: flex;
    flex-direction: row;
    align-items: center;

    line-height: 1.5rem;
}

.home-words-card-title {
    margin-bottom: 0.5rem;
}

.home-words-down {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
}
</style>
